import {Button, Card, Divider, Label, Flex, Box, useToasts, Callout, Stack, Spinner, Switch} from "bumbag";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";

import generalClasses from '../css/General.module.css';
import pitListClasses from '../css/PitList.module.css';
import Select from "react-select";

const PitFarmList = () => {
  let history = useHistory();
  const [listAssembly, setListAssembly] = useState([]);
  const [originalListAssembly, setOriginalListAssembly] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState({success: true, progress: true, fail: true, client: { value: null, label: 'Clientes: Sem Filtro' }});
  const [optionsClient, setOptionsClient] = useState([{ value: null, label: 'Clientes: Sem Filtro' }]);
  const toasts = useToasts();
  const token = localStorage.getItem('token');
  const host = process.env.REACT_APP_EASY_FARM_API_URL;
  useEffect(() => {
      fetchList();
      loadClients();
    },
    [host, token]
  );

  async function fetchList() {
    setLoading(true);
    try {
      const response = await axios.get(`${host}/pits-farms`);
      setListAssembly(response.data)
      setOriginalListAssembly(response.data);
      setLoading(false);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não foi possível carregar os PIT Fazenda!'})
      setLoading(false);
      console.log(e.response);
    }
  }

  async function loadClients() {
    try {
      let _response = await axios.get(`${host}/clients`);
      let clientes = _response.data.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      });
      clientes.unshift({ value: null, label: 'Clientes: Sem Filtro' })
      setOptionsClient(clientes)
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  function editHandler(assemblyLine) {
    history.push(`/pit/farm/cad/${assemblyLine.id}`);
  }

  function showMessage(title, message, type) {
    if (type === 'danger') {
      toasts.danger({title, message})
      return
    }
    if (type === 'success') {
      toasts.success({title, message: message})
      return
    }
    if (type === 'warning') {
      toasts.warning({title, message: message})
      return
    }
    toasts.info({title, message: message})

  }

  function processHandler(assemblyLine) {
    axios.put(`${host}/pits-farms/${assemblyLine.id}`, {}).then(
      () => {
        showMessage('Sucesso', 'Iniciando processo', 'success')
      }).catch(error => {
        if (error.message === 'Request failed with status code 401') {
          window.location = process.env.REACT_APP_DASHBOARD_URL;
          return;
        }
        showMessage('Erro', error.message, 'danger')
      }
    );
  }

  function showResultHandler(assemblyLine) {
    history.push(`/pit/farm/result/${assemblyLine.id}`);
  }

  function downloadHandler(assemblyLine) {
    window.open(`${host}/pits-farms/${assemblyLine.id}?action=csv`, "_blank");
  }

  function btVisualizarEtapasHandler(assemblyLine) {
    history.push(`/pit/farm/etapas/${assemblyLine.id}`);
  }

  const filterAssemblyLines = (status, clientId) => {
    let filter = filtering;
    let filtered = [];
    if (status !== null) {
      filter[status] = !filter[status];
    }
    if (clientId !== undefined) {
      filter.client = optionsClient.find((optionValue) => { if (optionValue.value === clientId) { return optionValue } });
    }
    filtered = originalListAssembly.filter(function(assembly) {
      return filter.client === undefined || filter.client === null || filter.client.value === null || assembly.clientId === filter.client.value;
    });
    filtered = filtered.filter(function(assembly) {
      return (
        (filter.success && assembly.status === 'SUCCESS') ||
        (filter.progress && assembly.status === 'PROGRESS') ||
        (filter.fail && assembly.status === 'FAIL')
      );
    });

    setFiltering(filter);
    setListAssembly(filtered);
  };

  const refreshList = async () => {
    await fetchList();
    filterAssemblyLines();
  }

  const cardsArray = listAssembly.map((assemblyLine) => (
    <Card className={pitListClasses['list-pit-item']} key={assemblyLine.id}>
      <Card.Header className={`${pitListClasses['list-pit-item-header']} ${pitListClasses[assemblyLine.status.toLowerCase()]}`}>
        <Card.Title>
          PIT Id: {assemblyLine.id} -
          <span className={pitListClasses['list-pit-item-header-names']}>
            {assemblyLine.clientLabel} / {assemblyLine.farmLabel}
          </span> => {assemblyLine.status}
        </Card.Title>
      </Card.Header>
      <Card.Content>
        <Flex>
          <Box className={pitListClasses['pit-item-card']}>
            <h3 className={pitListClasses['pit-item-card-header']}>PIT Fazenda: {assemblyLine.pitName}</h3>
            <Label><strong>Cliente: </strong>{assemblyLine.clientId} - {assemblyLine.clientLabel}</Label>
            <Label><strong>Fazenda: </strong>{assemblyLine.farmId} - {assemblyLine.farmLabel}</Label>
          </Box>
        </Flex>
        {
          assemblyLine.status === "PROGRESS" &&
          <Stack spacing="major-2">
            <Callout type="warning" title="Execução" width="600px">
              <Label><strong>Nome da etapa: </strong>{assemblyLine.currentStepName}</Label>
              <Label><strong>Número da etapa atual: </strong>{assemblyLine.currentStepNumber}</Label>
              <Label><strong>Total de etapas: </strong>{assemblyLine.totalSteps}</Label>
            </Callout>
          </Stack>
        }
        {assemblyLine.status === "FAIL" &&
          <Stack spacing="major-2">
            <Callout type="danger" title={assemblyLine.errorTitle} width="600px">
              {assemblyLine.errorMessage}
            </Callout>
          </Stack>
        }
      </Card.Content>
      <Card.Footer>
        <Flex>
          <Button palette="info" spacing="major-2" assemblyLineId={assemblyLine.id} onClick={() => editHandler(assemblyLine)}>Editar</Button>
          <Box width="20px" />
          <Button palette="info" spacing="major-2" assemblyLineId={assemblyLine.id} onClick={() => btVisualizarEtapasHandler(assemblyLine)}>Visualizar etapas</Button>
          <Box width="20px" />
          {
            assemblyLine.status === "SUCCESS" &&
            <Flex>
              <Button palette="success" spacing="major-2" assemblyLineId={assemblyLine.id} onClick={() => showResultHandler(assemblyLine)}>Visualizar dados</Button>
              <Box width="20px"/>
              <Button spacing="major-2" palette="success" variant="outlined" assemblyLineId={assemblyLine.id} onClick={() => downloadHandler(assemblyLine)}>Baixar csv</Button>
              <Box width="20px" />
            </Flex>
          }
          {
            assemblyLine.status !== "SUCCESS" &&
            <Button palette="warning" onClick={() => processHandler(assemblyLine)} margim="major-5">Reiniciar</Button>
          }
        </Flex>
      </Card.Footer>
    </Card>
  ));

  return (
    <div className={generalClasses['page-container']}>
      <Box className={generalClasses['top-buttons']}>
        <Flex>
          <Box width="55%" alignX="left">
            <Box paddingX="minor-2">Exibindo: {listAssembly.length} de um total de {originalListAssembly.length} PITs Fazenda</Box>
            <Flex>
              <Switch className={pitListClasses['filter-check']} palette="success" checked={filtering.success} label="Sucesso" onChange={() => filterAssemblyLines('success')} />
              <Switch className={pitListClasses['filter-check']} palette="warning" checked={filtering.progress} label="Em Execução" onChange={() => filterAssemblyLines('progress')} />
              <Switch className={pitListClasses['filter-check']} palette="danger" checked={filtering.fail} label="Com Falha" onChange={() => filterAssemblyLines('fail')} />
              <Box width="30px" />
              <Box width="220px">
                <Select size="small" placeholder="" value={filtering.client} options={optionsClient} onChange={(event) => filterAssemblyLines(null, event.value)} />
              </Box>
            </Flex>
          </Box>
          <Box width="45%" alignX="left" paddingY="10px">
            <Flex>
              <Button palette="success" onClick={() => history.push("/pit/farm/cad")}>Criar</Button>
              <Box width="30px" />
              <Button palette="primary" onClick={() => refreshList()}>Atualizar</Button>
              <Box width="30px" />
              <Button palette="gray" onClick={() => history.push("/")}>Voltar</Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Divider/>
      {loading && <Spinner className={generalClasses['spinner-position']} color="success" fontSize="80px" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
      {!loading && listAssembly.length > 0 && cardsArray}
      {listAssembly.length === 0 && <Box alignX="center"><br></br><Label></Label></Box>}
    </div>
  );
};

export default PitFarmList;

