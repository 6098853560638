import {Button, Card, Divider, Label, Flex, Box, useToasts, Callout, Stack, Spinner, Switch} from "bumbag";
import {Modal} from 'bumbag';
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";

import generalClasses from '../css/General.module.css';
import pitListClasses from '../css/PitList.module.css';
import Select from "react-select";

const PitFieldList = () => {
  let history = useHistory();
  const [listAssembly, setListAssembly] = useState([]);
  const [originalListAssembly, setOriginalListAssembly] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState({success: true, progress: true, fail: true, client: {value: null, label: 'Clientes: Sem Filtro'}, farm: {value: null, label: 'Fazendas: Selecione um Cliente'}, cropYear: {value: null, label: 'Ano Safra: Sem Filtro'}, field: { value: null, label: 'Talhões: Selecione uma Fazenda' }});
  const [optionsClient, setOptionsClient] = useState([{value: null, label: 'Clientes: Sem Filtro'}]);
  const [optionsFarm, setOptionsFarm] = useState([{value: null, label: 'Fazendas: Selecione um Cliente'}]);
  const [optionsField, setOptionsField] = useState([{value: null, name: 'Talhões: Selecione uma Fazenda'}]);
  const [optionsCropYear, setOptionsCropYear] = useState(['']);
  const toasts = useToasts();
  const token = localStorage.getItem('token');
  const host = process.env.REACT_APP_EASY_FARM_API_URL;

  useEffect(async () => {
    await loadScreen();
  }, []);

  function editHandler(assemblyLine) {
    history.push(`/pit/field/cad/${assemblyLine.id}`);
  }

  function btVisualizarEtapasHandler(assemblyLine) {
    history.push(`/pit/field/etapas/${assemblyLine.id}`);
  }

  function showMessage(title, message, type) {
    if (type === 'danger') {
      toasts.danger({title, message})
      return
    }
    if (type === 'success') {
      toasts.success({title, message: message})
      return
    }
    if (type === 'warning') {
      toasts.warning({title, message: message})
      return
    }
    toasts.info({title, message: message})
  }

  function processHandler(assemblyLine) {
    axios.put(`${host}/pits-fields/${assemblyLine.id}`, assemblyLine).then(() => {
      showMessage('Sucesso', 'Iniciando processo', 'success')
      history.push("/pit/field");
    }).catch(error => {
      if (error.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não consegui reprocessar!' });
    });
  }

  function downloadPdfHandler(assemblyLine) {
    axios.get(`${host}/pits-fields/${assemblyLine.id}?onlyTemporaryPdfLink=true`).then(response => {
      window.open(response.data.pdfLink, "_blank");
    }).catch(error => {
      if (error.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não consegui reprocessar!' });
    });
  }

  function downloadCsvHandler(assemblyLine) {
    axios({
      url: `${host}/pits-fields/${assemblyLine.id}?action=downloadCsv`,
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  async function loadScreen() {
    fetchList();
    loadClients();
    fetchCropYear();
  }

  async function fetchList() {
    setLoading(true);
    try {
      const response = await axios.get(`${host}/pits-fields`);
      setOriginalListAssembly(response.data);
      setListAssembly(response.data);
      setLoading(false);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não foi possível carregar os PIT Talhão!' })
      setLoading(false);
      console.log(e.response);
    }
  }

  async function loadClients() {
    try {
      let _response = await axios.get(`${host}/clients`);
      let clientes = _response.data.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      });
      clientes.unshift({ value: null, label: 'Clientes: Sem Filtro' })
      setOptionsClient(clientes)
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  async function fetchCropYear() {
    try {
      const response = await axios.get(`${host}/harvest-years`);
      let cont = 1;
      let cropYears = response.data.map(elem => {
        let retorno = {
          value: elem,
          label: elem,
        }
        cont++;
        return retorno
      })
      cropYears.unshift({value: null, label: 'Ano Safra: Sem Filtro'})
      setOptionsCropYear(cropYears);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  async function fetchClientFarms(clientId) {
    if (clientId === null) {
      setOptionsFarm([{ value: null, label: 'Fazendas: Selecione um Cliente' }]);
      return;
    }
    try {
      const response = await axios.get(`${host}/clients/${clientId}/farms`);
      let cont = 1;
      let farms = response.data.map(elem => {
        let retorno = {
          value: elem.id,
          label: elem.name,
          clientId: clientId,
          ...elem
        }
        cont++;
        return retorno
      })
      farms.unshift({ value: null, label: 'Fazendas: Sem Filtro' })
      setOptionsFarm(farms);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  async function fetchClientFarmFields(clientId, farmId, _cropYear) {
    try {
      const response = await axios.get(`${host}/clients/${clientId}/farms/${farmId}/areas?year=${_cropYear}`);
  
      let fields = response.data.map(elem => {
        const label = elem.block ? `${elem.block} | ${elem.field}` : elem.field;
        return {
          value: elem.id,
          label: label,
          ...elem
        }
      });
  
      fields.unshift({ value: null, label: 'Talhão: Sem Filtro' })
      setOptionsField(fields);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  const showEstatistics = (assemblyLine) => {
    if (assemblyLine.status === 'SUCCESS') {
      const statistic = assemblyLine.statistics;
      return (
        <Box className={pitListClasses['pit-item-card']}>
          <h3 className={pitListClasses['pit-item-card-header']}>Estatísticas</h3>
          <Label><strong>Q1: </strong>{statistic.q1}</Label>
          <Label><strong>Q3: </strong>{statistic.q3}</Label>
          <Label><strong>Min: </strong>{statistic.min}</Label>
          <Label><strong>Max: </strong>{statistic.max}</Label>
          <Label><strong>IV médio: </strong>{statistic.ivMean}</Label>
          <Label><strong>Desv. padrão: </strong>{statistic.stdDeviation}</Label>
          <Label><strong>Índice InCeres: </strong>{statistic.coefficientOfVariation}</Label>
        </Box>
      );
    }
    return (
      <Box className={pitListClasses['pit-item-card']}>
        <h3 className={pitListClasses['pit-item-card-header']}>Sem Estatísticas</h3>
      </Box>
    );
  }

  const showOffSeason = (assemblyLine) => {
    if (assemblyLine.offSeason.length > 0) {
      return(
        <Box className={pitListClasses['pit-item-card']}>
          <h3 className={pitListClasses['pit-item-card-header']}>Safrinha</h3>
          <Label><strong>Cultura: </strong>{assemblyLine.offSeason[0].crop}</Label>
          <Label><strong>Produtividade: </strong>{assemblyLine.offSeason[0].targetYield}</Label>
          <Label>Variedade: {assemblyLine.offSeason[0].varietyLabel}</Label>
        </Box>
      );
    }
    return(
      <Box className={pitListClasses['pit-item-card']}>
        <h3 className={pitListClasses['pit-item-card-header']}>Sem Safrinha</h3>
      </Box>
    );
  }

  async function excluirAssemblyLine(assemblyLine) {
    try {
      const response = await axios.delete(`${host}/pits-fields/${assemblyLine.id}`);
      if (response.status === 200)
        alert('AssemblyLine removida com sucesso');
    } catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      console.log(e.response);
    }
  }

  const filterAssemblyLines = (property, value) => {
    let filter = filtering;
    if (['success', 'progress', 'fail'].indexOf(property) !== -1) {
      filter[property] = !filter[property];
    }
    
    if (property === 'client') {
      filter.client = optionsClient.find((optionValue) => { if (optionValue.value === value) { return optionValue } });
      fetchClientFarms(value);
      filter.farm = { value: null, label: 'Fazendas: Sem Filtro' };
    }

    if (property === 'farm') {
      filter.farm = optionsFarm.find((optionValue) => { if (optionValue.value === value) { return optionValue } });
      if (filter.cropYear.value !== null) {
        fetchClientFarmFields(filter.client.value, filter.farm.value, value);
        filter.field = { value: null, label: 'Talhões: Sem Filtro' };
      }
    }

    if (property === 'cropYear') {
      filter.cropYear = optionsCropYear.find((optionValue) => { if (optionValue.value === value) { return optionValue } });
      fetchClientFarmFields(filter.client.value, filter.farm.value, value);
      filter.field = { value: null, label: 'Talhões: Sem Filtro' };
    }

    if (property === 'field') {
      filter.field = optionsField.find((optionValue) => { if (optionValue.value === value) { return optionValue } });
    }

    let filtered = originalListAssembly.filter(function(assembly) {
      return filter.client === undefined || filter.client === null || filter.client.value === null || assembly.clientId === filter.client.value;
    });
    filtered = filtered.filter(function(assembly) {
      return filter.farm === undefined || filter.farm === null || filter.farm.value === null || assembly.farmId === filter.farm.value;
    });
    filtered = filtered.filter(function(assembly) {
      return filter.cropYear === undefined || filter.cropYear === null || filter.cropYear.value === null || assembly.cropYear === filter.cropYear.value;
    });
    filtered = filtered.filter(function(assembly) {
      return filter.field === undefined || filter.field === null || filter.field.value === null || assembly.fieldId === filter.field.value;
    });
    filtered = filtered.filter(function(assembly) {
      return (
        (filter.success && assembly.status === 'SUCCESS') ||
        (filter.progress && assembly.status === 'PROGRESS') ||
        (filter.fail && assembly.status === 'FAIL')
      );
    });

    setFiltering(filter);
    setListAssembly(filtered);
  };

  const refreshList = async () => {
    await fetchList();
    filterAssemblyLines();
  }

  const cardsArray = listAssembly.map(assemblyLine => (
    <Card className={pitListClasses['list-pit-item']} key={assemblyLine.id}>
      <Card.Header className={`${pitListClasses['list-pit-item-header']} ${pitListClasses[assemblyLine.status.toLowerCase()]}`}>
        <Card.Title>
          PIT Id: {assemblyLine.id} -
          <span className={pitListClasses['list-pit-item-header-names']}>
            {assemblyLine.clientLabel} / {assemblyLine.farmLabel} / {assemblyLine.cropYear} / {assemblyLine.fieldLabel}
          </span> => {assemblyLine.status}
        </Card.Title>
      </Card.Header>
      <Card.Content>
        <Flex>
          <Box className={pitListClasses['pit-item-card']}>
            <h3 className={pitListClasses['pit-item-card-header']}>PIT Talhão: {assemblyLine.pitName}</h3>
            <Label><strong>Cliente: </strong>{assemblyLine.clientId} - {assemblyLine.clientLabel}</Label>
            <Label><strong>Fazenda: </strong>{assemblyLine.farmId} - {assemblyLine.farmLabel}</Label>
            <Label><strong>Ano safra: </strong>{assemblyLine.cropYear}</Label>
            <Label><strong>Quadra: </strong>{assemblyLine.fieldBlock}</Label>
            <Label><strong>Talhão: </strong>{assemblyLine.fieldId} - {assemblyLine.fieldLabel}</Label>
          </Box>
          {showEstatistics(assemblyLine)}
        </Flex>
        <Flex>
          <Box className={pitListClasses['pit-item-card']}>
            <h3 className={pitListClasses['pit-item-card-header']}>Safra</h3>
            <Label><strong>Cultura: </strong>{assemblyLine.harvest.crop}</Label>
            <Label><strong>Produtividade: </strong>{assemblyLine.harvest.targetYield}</Label>
            <Label><strong>Variedade: </strong>{assemblyLine.harvest.varietyLabel}</Label>
          </Box>
          {showOffSeason(assemblyLine)}
        </Flex>
        {
          assemblyLine.status === "PROGRESS" &&
          <Stack spacing="major-2">
            <Callout type="warning" title="Execução" width="600px">
              <Label><strong>Nome da etapa: </strong>{assemblyLine.currentStepName}</Label>
              <Label><strong>Número da etapa atual: </strong>{assemblyLine.currentStepNumber}</Label>
              <Label><strong>Total de etapas: </strong>{assemblyLine.totalSteps}</Label>
            </Callout>
          </Stack>
        }
        {assemblyLine.status === "FAIL" &&
          <Stack spacing="major-2">
            <Callout type="danger" title={assemblyLine.errorTitle} width="600px">
              {assemblyLine.errorMessage}
            </Callout>
          </Stack>
        }
      </Card.Content>
      <Card.Footer>
        <Flex>
          <Button palette="info" spacing="major-2" assemblyLineId={assemblyLine.id} onClick={() => editHandler(assemblyLine)}>Editar</Button>
          <Box width="20px" />
          <Button palette="info" spacing="major-2" assemblyLineId={assemblyLine.id} onClick={() => btVisualizarEtapasHandler(assemblyLine)}>Visualizar etapas</Button>
          <Box width="20px" />
          {
            assemblyLine.status === "SUCCESS" &&
            <Flex>
              <Button spacing="major-2" variant="outlined" palette="success" assemblyLineId={assemblyLine.id} onClick={() => downloadPdfHandler(assemblyLine)}>Baixar PDF</Button>
              <Box width="10px" />
              <Button spacing="major-2" variant="outlined" palette="success" assemblyLineId={assemblyLine.id} onClick={() => downloadCsvHandler(assemblyLine)}>Baixar CSV</Button>
              <Box width="20px" />
            </Flex>
          }
          {
            assemblyLine.status !== "SUCCESS" &&
            <Flex>
              <Button palette="warning" onClick={() => processHandler(assemblyLine)} margim="major-5">Reiniciar</Button>
              <Box width="20px" />
            </Flex>
          }

          <Modal.State>
            <Modal.Disclosure use={Button} palette="danger">Remover</Modal.Disclosure>
            <Modal>
              <Card>
                <Card.Header>
                  <Card.Title>Atenção!</Card.Title>
                </Card.Header>
                <Card.Content>
                  <Box>Tem certeza que deseja remover o PIT Talhão {assemblyLine.id} - {assemblyLine.pitName}?</Box>
                </Card.Content>
                <Card.Footer>
                  <Box alignX="center">
                    <Flex>
                      <Button palette="danger" spacing="major-2" onClick={() => excluirAssemblyLine(assemblyLine)} margim="major-5">Remover</Button>
                      <Box width="20px" />
                      <Modal.Disclosure spacing="major-2" use={Button} palette="gray" variant="outlined">Cancelar</Modal.Disclosure>
                    </Flex>
                  </Box>
                </Card.Footer>
              </Card>
            </Modal>
          </Modal.State>
        </Flex>
      </Card.Footer>
    </Card>
  ));

  return (
    <div className={generalClasses['page-container']}>
      <Box className={generalClasses['top-buttons']}>
        <Flex>
          <Box width="80%" alignX="left">
            <Box paddingX="minor-2">Exibindo: {listAssembly.length} de um total de {originalListAssembly.length} PITs Talhão</Box>
            <Flex>
              <Switch className={pitListClasses['filter-check']} palette="success" checked={filtering.success} label="Sucesso" onChange={() => filterAssemblyLines('success')} />
              <Switch className={pitListClasses['filter-check']} palette="warning" checked={filtering.progress} label="Em Execução" onChange={() => filterAssemblyLines('progress')} />
              <Switch className={pitListClasses['filter-check']} palette="danger" checked={filtering.fail} label="Com Falha" onChange={() => filterAssemblyLines('fail')} />
              <Box width="30px" />
              <Box width="220px">
                <Select size="small" placeholder="" value={filtering.client} options={optionsClient} onChange={(event) => filterAssemblyLines('client', event.value)} />
              </Box>
              <Box width="30px" />
              <Box width="220px">
                <Select size="small" placeholder="" value={filtering.farm} options={optionsFarm} onChange={(event) => filterAssemblyLines('farm', event.value)} />
              </Box>
              <Box width="30px" />
              <Box width="140px">
                <Select size="small" placeholder="" value={filtering.cropYear} options={optionsCropYear} onChange={(event) => filterAssemblyLines('cropYear', event.value)} />
              </Box>
              <Box width="30px" />
              <Box width="200px">
                <Select size="small" placeholder="" value={filtering.field} options={optionsField} onChange={(event) => filterAssemblyLines('field', event.value)} />
              </Box>
            </Flex>
          </Box>
          <Box width="20%" alignX="right" paddingY="10px" paddingX="20px">
            <Flex>
              <Button palette="success" onClick={() => history.push("/pit/field/cad")}>
                Criar
              </Button>
              <Box width="30px" />
              <Button palette="primary" onClick={() => refreshList()}>Atualizar</Button>
              <Box width="30px" />
              <Button palette="gray" onClick={() => history.push("/")}>Voltar</Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Divider/>
      {loading && <Spinner className={generalClasses['spinner-position']} color="success" fontSize="80px" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
      {!loading && listAssembly.length > 0 && cardsArray}
    </div>
  );
};

export default PitFieldList;

